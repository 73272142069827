.ag-root ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  -webkit-appearance: none;
}
.ag-root ::-webkit-scrollbar-thumb {
  background-color: rgb(110, 110, 110);
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.ag-cell,
.ag-react-container {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.ag-center-cols-container {
  min-width: 100% !important;
}

.ag-cell-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}
